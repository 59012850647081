class CreateUserCardNew extends React.Component{
    constructor(props){
        super(props);
        this.props = props;
    }

    componentDidUpdate(){
        componentHandler.upgradeDom();
    }

    componentDidMount(){
        componentHandler.upgradeDom();
    }

    render(){
        return (
            <div className="fullWidth allow-overflow zindex2 createUserCardNew">
                <div className="mdl-card__title ">
                    <h2 className="mdl-card__title-text">{DkScanHowLocalisation.t("web_users_subtitle_createsingleuser")}</h2>
                </div>
                <div className="fullWidth ">
                    <table className="mdl-data-table edit_users_table fullWidth create_user_table">
                        <tbody>
                            <UserLine account={this.props.account} accountGroups={this.props.accountGroups} currentUser={this.props.currentUser} reachedUserLimit={this.props.reachedUserLimit} addGroup={this.props.addGroup}/>
                        </tbody>
                    </table>
                </div>
            </div>
    );
    }
}
CreateUserCardNew.defaultProps = {
};
CreateUserCardNew.propTypes ={
    addGroup:React.PropTypes.func.isRequired,
    reachedUserLimit:React.PropTypes.func.isRequired,
    account:React.PropTypes.object.isRequired,
    accountGroups:React.PropTypes.array.isRequired,
    currentUser:React.PropTypes.object.isRequired
};

class UserLine extends React.Component{
    constructor(props){
        super(props);
        this.props = props;
        this.state = {name:'', password:'', email:'', errorMsg:'', role:'viewer', selectedGroups:{}};
        this.fieldUpdated = this.fieldUpdated.bind(this);
        this.userUpdated = this.userUpdated.bind(this);
        this.userUpdateFailed = this.userUpdateFailed.bind(this);
        this.toggleGroup = this.toggleGroup.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.onTypeSelect = this.onTypeSelect.bind(this);
        this.onCreated = this.onCreated.bind(this);
        this.onLineCheck = this.onLineCheck.bind(this);
        this.createUser = this.createUser.bind(this);
        this.createUserSucces = this.createUserSucces.bind(this);
        this.createUserFailed = this.createUserFailed.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        console.log("userline did update " + this.props.userId);
        componentHandler.upgradeDom();
    }

    componentDidMount(){
        componentHandler.upgradeDom();
    }

    onLineCheck(e){
        this.props.onCheck(this.props.userId, e.target.checked);
    }

    onCreated(user){
        var userAndGroups= {};
        var userId = user.uid;
        userAndGroups['users/' + userId + "/uid"] = userId;
        userAndGroups['users/' + userId + "/email"] = user.email;
        userAndGroups['users/' + userId + "/accountKey"] = this.props.account.accountKey;
        userAndGroups['users/' + userId + "/createdTime"] = firebase.database.ServerValue.TIMESTAMP,
        userAndGroups['users/' + userId + "/name"] = this.state.name;
        userAndGroups['users/' + userId + "/role"] = this.state.role;
        //userAndGroups['users/' + userId + "/modifiedTime"] = firebase.database.ServerValue.TIMESTAMP;

        userAndGroups["accountUsers/" + this.props.account.accountKey + "/" + userId] = this.state.role;

        var selectedGroups = DkScanHowHelpers.objectToArray( this.state.selectedGroups );
        selectedGroups.forEach(function( group ){
            if( group.valueOf() ) {
                userAndGroups['accountGroups/' + this.props.account.accountKey + '/'+ group.key + '/users/' + userId] = true;
                userAndGroups['users/' + userId + "/groups/" + group.key] = true;
            }else{
                userAndGroups['accountGroups/' + this.props.account.accountKey + '/'+ group.key + '/users/' + userId] = null;
                userAndGroups['users/' + userId + "/groups/" + group.key] = null
            }
        }, this );



        firebase.database().ref().update(userAndGroups).then(
            this.userUpdated,
            this.userUpdateFailed
        );
    }

    userUpdated(){
        this.setState( {name:'', password:'', email:'', errorMsg:'', role:'viewer', selectedGroups:{} } );
        console.log("UserUpdated");
        DkScanHowHelpers.displayModal(false);
    }

    userUpdateFailed(error) {
        this.setState({errorMsg:DkScanHowLocalisation.t("web_general_errormsg_anerroroccurred")});
        DkScanHowHelpers.logError(error);
        DkScanHowHelpers.displayModal(false);
    }

    fieldUpdated(e) {
        const key = e.target.name;
        const value = e.target.value;
        this.setState({[key]:value});
    }

    toggleGroup(e){
        var selectedGroupsTemp = this.state.selectedGroups;
        selectedGroupsTemp[e.target.name] = e.target.checked;
        this.setState({selectedGroups:selectedGroupsTemp});
    }

    onTypeSelect(e){
        var role = e.target.value
        if (this.props.reachedUserLimit(role)) {
            this.setState({errorMsg: DkScanHowLocalisation.t("web_users_errormsg_youcantcreatemoreusers_params").replace("%1",role)});
        }else{
            this.setState({role:role});
        }

    }

    addGroup(newGroupName) {
        this.props.addGroup(newGroupName);
    }

    createUser() {
        if (this.props.reachedUserLimit(this.state.role)) {
            this.setState({errorMsg: DkScanHowLocalisation.t("web_users_errormsg_youcantcreatemoreusers_params").replace("%1",this.state.role)});
        } else {
            if (this.state.name == "") {
                this.setState({errorMsg: DkScanHowLocalisation.t("web_users_errormsg_nameisarequiredfield")});
            } else if (this.state.email == "") {
                this.setState({errorMsg: DkScanHowLocalisation.t("web_users_errormsg_emailisarequiredfield")});
            } else if (this.state.password == "") {
                this.setState({errorMsg: DkScanHowLocalisation.t("web_users_errormsg_passwordisarequiredfield")});
            } else {
                DkScanHowHelpers.displayModal(true);
                DkScanHowHelpers.getSecondaryApp().auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then((user) => this.createUserSucces(user), (error) => DkScanHowHelpers.createUserFailed(error, this.createUserFailed));
            }
        }
    }

    createUserSucces(user){
        user.sendEmailVerification();
        console.log("Auth User Created");
        DkScanHowHelpers.getSecondaryApp().auth().signOut();
        this.setState({errorMsg:""});
        this.onCreated(user);
    }

    createUserFailed(errorMsg){
        this.setState({errorMsg:errorMsg});
        DkScanHowHelpers.displayModal(false);
    }

    render(){
        var actionButtons = <ScanHowButton onClick={this.createUser} text={DkScanHowLocalisation.t("web_users_btn_create")}/>;
        var userRole = <select name="userType" onChange={this.onTypeSelect} value={this.state.role}>
                        <option value="viewer" >{DkScanHowLocalisation.t("web_general_terms_viewer")}</option>
                        <option value="creator" >{DkScanHowLocalisation.t("web_general_terms_creator")}</option>
                        <option value="admin" >{DkScanHowLocalisation.t("web_general_terms_admin")}</option>
                    </select>;

        return (
            <tr>
                <td>
                    <div>&nbsp;</div>
                </td>
                <td><ScanHowInputTextField fieldName="name" placeholderText={DkScanHowLocalisation.t("web_users_placeholder_name")} onChange={this.fieldUpdated} customClass="mdl-textfield--floating-label" value={this.state.name}/>
                    <div className="tooltip">
                        <div className="tooltipButton"/>
                        <span className="tooltiptext">{DkScanHowLocalisation.t("web_users_tooltip_name")}</span>
                    </div>
                </td>
                <td><ScanHowInputTextField fieldName="email" placeholderText={DkScanHowLocalisation.t("web_users_placeholder_email")} onChange={this.fieldUpdated} customClass="mdl-textfield--floating-label" value={this.state.email}/>
                    <div className="tooltip">
                        <div className="tooltipButton"/>
                        <span className="tooltiptext">{DkScanHowLocalisation.t("web_users_tooltip_email")}</span>
                    </div>
                </td>
                <td><ScanHowInputTextField fieldName="password" placeholderText={DkScanHowLocalisation.t("web_users_placeholder_password")} onChange={this.fieldUpdated}
                                           customClass="mdl-textfield--floating-label" value={this.state.password}/>
                    <div className="tooltip">
                        <div className="tooltipButton"/>
                        <span className="tooltiptext">{DkScanHowLocalisation.t("web_users_tooltip_password")}</span>
                    </div>
                </td>
                { this.state.role == "admin" ?
                    <td>&nbsp;</td> :
                    <td>
                        <ScanHowPicker fieldName="groupPicker"
                                       selectedItems={this.state.selectedGroups ? this.state.selectedGroups : {}}
                                       parentId={"UserLine_" }
                                       addItemPlaceholderText={DkScanHowLocalisation.t("web_users_placeholder_creategroup")}
                                       onToggleItem={this.toggleGroup} onAddItem={this.addGroup}
                                       options={this.props.accountGroups}/>
                        <div className="tooltip">
                            <div className="tooltipButton"/>
                            <span className="tooltiptext">{DkScanHowLocalisation.t("web_users_tooltip_groups")}</span>
                        </div>
                    </td>
                }
                <td>
                    {userRole}
                    <div className="tooltip">
                        <div className="tooltipButton"/>
                        <span className="tooltiptext">{DkScanHowLocalisation.t("web_users_tooltip_usertype")}</span>
                    </div>
                </td>
                <td>
                    {actionButtons}
                </td>
                <td>
                    <div className="accountFormErrorMessage">{this.state.errorMsg}</div>
                </td>
            </tr>
        );
    }


}
UserLine.propTypes ={
    addGroup:React.PropTypes.func.isRequired,
    account:React.PropTypes.object.isRequired,
    accountGroups:React.PropTypes.array.isRequired,
    hasUpdatedSelected:React.PropTypes.func,
    currentUser:React.PropTypes.object.isRequired
};

class UserLineExisting extends React.Component{
    constructor(props){
        super(props);
        this.state = {"passwordPlaceholderText" : DkScanHowLocalisation.t("web_users_placeholder_newpassword")};
        this.props = props;
        this.fieldUpdated = this.fieldUpdated.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.toggleGroup = this.toggleGroup.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.onTypeSelect = this.onTypeSelect.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.updateUserClicked = this.updateUserClicked.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        //console.log("userline did update " + this.props.userId);
        if( (this.props.user.changePassword == "" || this.props.user.changePassword == null) && this.state.passwordPlaceholderText != DkScanHowLocalisation.t("web_users_placeholder_newpassword")){
            this.setState( {"passwordPlaceholderText" : DkScanHowLocalisation.t("web_users_placeholder_newpassword")} );
        }
        componentHandler.upgradeDom();
    }

    componentDidMount(){
        componentHandler.upgradeDom();
    }

    onCheck(e){
        this.props.onCheck(this.props.userId, e.target.checked);
    }

    deleteUser(){
        this.props.deleteUser(this.props.user.uid);
    }

    updateUserClicked(){
        this.updateUser(this.props.userId);
    }

    updateUser(userId){
        if( !(userId) ){
            console.log("MISSING USER ID");
            return;
        }

        this.props.saveUser(userId);
    }

    fieldUpdated(e) {
        const key = e.target.name;
        const value = e.target.value;
        if( key == "changePassword" ){
            this.setState( {"passwordPlaceholderText" : ( value ? DkScanHowLocalisation.t("web_users_placeholder_newpasswordmin6chars") : DkScanHowLocalisation.t("web_users_placeholder_newpassword") )} );
        }

        this.props.updateUserField(this.props.user.uid, key, value);
    }

    toggleGroup(e) {
        var selectedGroupsTemp = this.props.user.groups ? this.props.user.groups : {};
        selectedGroupsTemp[e.target.name] = e.target.checked;
        this.props.updateUserField(this.props.user.uid, "groups", selectedGroupsTemp);
    }

    onTypeSelect(e){
        this.props.updateUserField(this.props.user.uid, "role", e.target.value);
    }

    addGroup(newGroupName) {
        this.props.addGroup(newGroupName);
    }

    render(){
        var uniqueId = this.props.userId ? this.props.userId : "";

        var userRole = null;
        var actionButtons = null;
        if( this.props.userId ){
            actionButtons = <div>
                <ScanHowButton text={DkScanHowLocalisation.t("web_users_btn_save")} onClick={this.updateUserClicked} customClass="scanhowGreen"/>
                { uniqueId != this.props.currentUser.uid ? <ScanHowButton text={DkScanHowLocalisation.t("web_users_btn_delete")} onClick={this.deleteUser} customClass="scanhowRed"/> : null}
            </div>;

            if( this.props.user.role == "admin"){
                userRole = <span>admin</span>;
            }else{
                userRole = <select name="userType" onChange={this.onTypeSelect} value={this.props.user.role}>
                    <option value="viewer" >{DkScanHowLocalisation.t("web_general_terms_viewer")}</option>
                    <option value="creator" >{DkScanHowLocalisation.t("web_general_terms_creator")}</option>
                </select>;
            }
        }else{
            actionButtons = <ScanHowButton onClick={this.createUser} text="Opret"/>;

            userRole = <select name="userType" onChange={this.onTypeSelect} >
                <option value="viewer" >{DkScanHowLocalisation.t("web_general_terms_viewer")}</option>
                <option value="creator" >{DkScanHowLocalisation.t("web_general_terms_creator")}</option>
                <option value="admin" >{DkScanHowLocalisation.t("web_general_terms_admin")}</option>
            </select>;
        }

        return (
            <tr>
                <td>
                    { this.props.userId
                        ?
                        <ScanhowCheckbox checkBoxName={"CB_"+this.props.userId}
                                         parentId="UserLine" checkBoxText=""
                                         onCheck={this.onCheck}
                                         checked={this.props.checked}/>
                        : <div>&nbsp;</div>

                    }
                </td>
                <td><ScanHowInputTextField fieldName="name" placeholderText={DkScanHowLocalisation.t("web_users_placeholder_name")} onChange={this.fieldUpdated} customClass="mdl-textfield--floating-label" value={this.props.user.name}/></td>
                <td><ScanHowInputTextField fieldName="email" placeholderText={DkScanHowLocalisation.t("web_users_placeholder_email")} onChange={this.fieldUpdated} customClass="mdl-textfield--floating-label" value={this.props.user.email}/></td>
                <td><ScanHowInputTextField fieldName="changePassword" placeholderText={this.state.passwordPlaceholderText} onChange={this.fieldUpdated} customClass="mdl-textfield--floating-label" value={this.props.user.changePassword}/></td>
                <td>{ this.props.user.role == "admin" ? "" :
                    <ScanHowPicker fieldName="groupPicker"
                                   selectedItems={this.props.user.groups ? this.props.user.groups : {}}
                                   parentId={"UserLine_" + uniqueId} addItemPlaceholderText={DkScanHowLocalisation.t("web_users_placeholder_creategroup")}
                                   onToggleItem={this.toggleGroup} onAddItem={this.addGroup}
                                   options={this.props.accountGroups}/>
                }</td>

                <td>
                    {userRole}
                </td>
                <td>
                    {actionButtons}
                    <div className="accountFormErrorMessage" style={{marginLeft:0+"px"}}>{this.props.user.errorMsg}</div>
                </td>
            </tr>
        );
    }
}
UserLineExisting.propTypes ={
    addGroup:React.PropTypes.func.isRequired,
    saveUser:React.PropTypes.func.isRequired,
    deleteUser:React.PropTypes.func.isRequired,
    account:React.PropTypes.object.isRequired,
    accountGroups:React.PropTypes.array.isRequired,
    currentUser:React.PropTypes.object.isRequired
};


class PrintUserLine extends React.Component{
    constructor(props){
        super(props);
        this.props = props
    }

    componentDidUpdate(){
        componentHandler.upgradeDom();
    }

    componentDidMount(){
        componentHandler.upgradeDom();
    }

    render(){
        return (
            <tr>
                <td>{this.props.user.name}</td>
                <td>{this.props.user.email}</td>
                <td>{DkScanHowHelpers.selectedGroupsAsString(this.props.user.groups, this.props.accountGroups)}</td>
                <td>{this.props.user.role}</td>
            </tr>
        );
    }
}
PrintUserLine.defaultProps = {
};
PrintUserLine.propTypes ={
};

class PrintUsersList extends React.Component{
    constructor(props){
        super(props);
        this.props = props;
        this.state ={};
    }

    componentDidUpdate(){
        componentHandler.upgradeDom();
    }

    componentDidMount(){
        componentHandler.upgradeDom();

        DkScanHowHelpers.getAccountGroupsAsArray(this.props.account.accountKey, (accountGroups)=> {

            this.setState({accountGroups: (accountGroups ? accountGroups : [])}, ()=>{

                firebase.database().ref('accountUsers/' + this.props.account.accountKey).on('value', (snapshot) => {
                    let userIds = [];
                    let users = [];

                    if (snapshot && snapshot.val()) {
                        Promise.all(
                            Object.keys(snapshot.val()).map((key) => {
                                return firebase.database().ref('users/' + key).once('value').then(snapshot => {
                                    /*console.log('got response ' + key);*/
                                    return snapshot.val();
                                }, function (error) {
                                    // An error happened.
                                    DkScanHowHelpers.logError(error);
                                });
                            })
                        ).then(
                            (defs) => {
                                let usersObject = {};
                                for (var index = 0; defs && index < defs.length; index++) {
                                    if (defs[index]) {
                                        usersObject [defs[index].uid] = defs[index];
                                    }
                                }
                                this.setState({organisationUsers: usersObject});
                            },
                            (defs) => {
                                console.log("Promise fail");
                                DkScanHowHelpers.logError(error);
                                //errorCallback();
                            }
                        );
                    }
                }, function (error) {
                    // An error happened.
                    console.log("Error while reading users: " + error)
                });
            });
        });
    }

    render() {
        var organisationUsersArray = [];
        if (this.state.organisationUsers) {
            organisationUsersArray = Object.keys(this.state.organisationUsers).map((userKey) => {
                return <PrintUserLine user={this.state.organisationUsers[userKey]} key={userKey} accountGroups={this.state.accountGroups}/>;
            }, this);
        }
        return (
            <div className="PrintUsersList">
                <table className="left">
                    <thead>
                    <tr>
                        <th>{DkScanHowLocalisation.t("web_users_columnheader_name")}</th>
                        <th>{DkScanHowLocalisation.t("web_users_columnheader_email")}</th>
                        <th>{DkScanHowLocalisation.t("web_users_columnheader_groups")}</th>
                        <th>{DkScanHowLocalisation.t("web_users_columnheader_usertype")}</th>
                    </tr>
                    </thead>
                    <tbody>
                        {organisationUsersArray}
                    </tbody>
                </table>
            </div>
        );
    }
}
PrintUsersList.defaultProps = {
};
PrintUsersList.propTypes ={

};

class EditUsersCard extends React.Component{
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {toggleAllChecked:false, checkedUsers:{}, checkedUsersCount:0, isUpdatingSelected:false, updatingUsersArray:{},isDeletingSelected:false};
        this.updateSelected = this.updateSelected.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.updateUserField = this.updateUserField.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.saveUsers = this.saveUsers.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.deleteUsers = this.deleteUsers.bind(this);
        this.printUsers = this.printUsers.bind(this);
    }

    componentDidUpdate(){
        componentHandler.upgradeDom();
    }

    componentDidMount() {
    }

    updateUserField(uid, key, value) {
        this.props.updateUserField(uid, key, value);
    }

    saveUsers(userIdsToSave){
        this.props.saveUsers(userIdsToSave);
    }
    deleteUser(uid) {
        this.props.deleteUser(uid);
    }

    deleteUsers(userIdsToDelete) {
        this.props.deleteUsers(userIdsToDelete);
    }

    saveUser(uid) {
        this.saveUsers([uid]);
    }

    toggleAll(e){
        console.log("toggle all");
        if( e.target.checked ){
            var tempCheckedUsers = this.state.checkedUsers;
            var organisationUsersArray = Object.keys(this.props.organisationUsers).map((key) => {
                return Object.assign(this.props.organisationUsers[key], {key});
            })

            for(var index = 0 ; organisationUsersArray && index < organisationUsersArray.length ; index++){
                tempCheckedUsers[organisationUsersArray[index].uid] = true;
            }

            this.setState({checkedUsers:tempCheckedUsers, checkedUsersCount:organisationUsersArray.length, toggleAllChecked:true });
        }else{
            this.setState({checkedUsers:{}, checkedUsersCount:0, toggleAllChecked:false});
        }
    }

    onCheck(uid, value){
        console.log("on check");
        var tempCheckedUsers = this.state.checkedUsers;
        if( value == true ){
            tempCheckedUsers[uid] = true;
        }else{
            delete tempCheckedUsers[uid];
        }
        this.setState({checkedUsers: tempCheckedUsers, checkedUsersCount: DkScanHowHelpers.objectToArray(tempCheckedUsers).length} );
    }

    updateSelected(){
        console.log("UpdateSelected()");
        var checkedUsersArray = Object.keys(this.state.checkedUsers).map((key) => {
            return key;
        })
        this.saveUsers(checkedUsersArray);
    }

    deleteSelected(){
        //DkScanHowHelpers.displayModal(true);

        var checkedUsersArray = Object.keys(this.state.checkedUsers).map((key) => {
            return key;
        })
        this.deleteUsers(checkedUsersArray);
    }

    printUsers(){
        //window.location = "/institution#users/print";
        var url = "/institution#users/print"
        console.log("opening :" + url);
        window.open(url);
        //DkScanHowHelpers.switchPrintMode();
    }

    render(){
        let usersArray = [];
        /*console.log("EditUsersCard render " + this.state.checkedUsersCount + " / " + JSON.stringify(this.state.checkedUsers) + " / " + JSON.stringify(this.state.updatingUsersArray) );*/
        if( this.props.organisationUsers ){

            var organisationUsersArray = Object.keys(this.props.organisationUsers).map((key) => {
                return Object.assign(this.props.organisationUsers[key], {key});
            })

            for(var index = 0 ; organisationUsersArray && index < organisationUsersArray.length ; index++) {
                if( organisationUsersArray[index] && organisationUsersArray[index].uid ) {
                    var userId = organisationUsersArray[index].uid;
                    var checked = this.state.checkedUsers[userId] ? true : false;
                    usersArray.push(<UserLineExisting key={userId} userId={userId}
                                                      user={organisationUsersArray[index]}
                                                      updateUserField={this.updateUserField}
                                                      saveUser={this.saveUser}
                                                      deleteUser={this.deleteUser}
                                                      account={this.props.account}
                                                      addGroup={this.props.addGroup}

                                                accountGroups={this.props.accountGroups} onCheck={this.onCheck}
                                              checked={checked }
                                              currentUser={this.props.currentUser}/>)
                }
            }
        }

        var areButtonsDisabled = this.state.checkedUsersCount == 0 || this.state.isUpdatingSelected || this.state.isDeletingSelected;

        return(
            <div className="fullWidth allow-overflow editUsersCard">
                <div className="mdl-card__title">
                    <h2 className="mdl-card__title-text">{DkScanHowLocalisation.t("web_users_subtitle_editusers")}</h2>
                </div>
                <div className="fullWidth">
                    <div className="editUsersCardMenu">
                        <ScanHowButton text={DkScanHowLocalisation.t("web_users_btn_saveselected")} onClick={this.updateSelected} id="updateAllSelectedUsersBtn" isDisabled={ areButtonsDisabled } customClass="scanhowGreen"/>
                        <ScanHowButton text={DkScanHowLocalisation.t("web_users_btn_deleteselected")} onClick={this.deleteSelected} isDisabled={ areButtonsDisabled } customClass="scanhowRed"/>
                        &nbsp;&nbsp;<a className="cursorPointer" onClick={this.printUsers}>{DkScanHowLocalisation.t( "web_users_link_print" )}</a>
                    </div>

                    <table className="mdl-data-table edit_users_table fullWidth" >
                        <thead>
                        <tr>
                            <th>
                                <ScanhowCheckbox checkBoxName={"CB_ToggleAllUsers"}
                                                 parentId="EditUsersCard" checkBoxText=""
                                                 onCheck={this.toggleAll}
                                                 checked={this.state.toggleAllChecked}/>
                            </th>
                            <th>{DkScanHowLocalisation.t("web_users_columnheader_name")}</th>
                            <th>{DkScanHowLocalisation.t("web_users_columnheader_email")}</th>
                            <th>{DkScanHowLocalisation.t("web_users_columnheader_newpassword")}</th>
                            <th>{DkScanHowLocalisation.t("web_users_columnheader_group")}</th>
                            <th>{DkScanHowLocalisation.t("web_users_columnheader_usertype")}</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {usersArray}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
EditUsersCard.propTypes ={
    addGroup:React.PropTypes.func.isRequired,
    organisationUsers:React.PropTypes.object,
    accountGroups:React.PropTypes.array.isRequired,
    currentUser:React.PropTypes.object.isRequired
};

class UserAdministrationPage extends React.Component{
    constructor(props){
        super(props);
        this.props = props;
        this.state = {accountGroups:[], organisationUsers:{}};
        this.updateUserField = this.updateUserField.bind(this);
        /*this.saveUser = this.saveUser.bind(this);*/
        this.saveUsers = this.saveUsers.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.deleteUsers = this.deleteUsers.bind(this);
        this.reachedUserLimitNew = this.reachedUserLimitNew.bind(this)
        this.addGroup = this.addGroup.bind(this);
        this.usersUpdateFailed = this.usersUpdateFailed.bind(this);





    }

    componentDidUpdate(){
        componentHandler.upgradeDom();
    }

    componentDidMount(){
        componentHandler.upgradeDom();
        DkScanHowHelpers.getAccountGroupsAsArray(this.props.account.accountKey, (accountGroups)=> {

            this.setState({accountGroups: (accountGroups ? accountGroups : [])}, ()=>{

                firebase.database().ref('accountUsers/' + this.props.account.accountKey).on('value', (snapshot) => {
                    let userIds = [];
                    let users = [];

                    if (snapshot && snapshot.val()) {

                        Promise.all(
                            Object.keys(snapshot.val()).map((key) => {

                                //For initial load from DB
                                return firebase.database().ref('users/' + key).once('value').then(snapshot => {
                                    /*console.log('got response ' + key);*/
                                    return snapshot.val();
                                }, function (error) {
                                    // An error happened.
                                    DkScanHowHelpers.logError(error);
                                });
                            })
                        ).then(
                            (defs) => {

                                var count = 0;

                                let usersObject = {};
                                for (var index = 0; defs && index < defs.length; index++) {
                                    if (defs[index]) {
                                        usersObject [defs[index].uid] = defs[index];
                                    }
                                }
                                this.setState({organisationUsers: usersObject});
                            },
                            (defs) => {
                                console.log("Promise fail");
                                DkScanHowHelpers.logError(error);
                                //errorCallback();
                            }
                        );
                    }
                }, function (error) {
                    // An error happened.
                    console.log("Error while reading users: " + error)
                });
            });
        });
    }

    reachedUserLimitNew(role){
        var roleCount = 0
        Object.keys(this.state.organisationUsers).map((key) => {
            if (Object.assign(this.state.organisationUsers[key]).role == role) {
                roleCount++;
            }
        });
        console.log("users with role :'" + role + "'=" + roleCount);
        var limit = 0;
        if( role == "viewer") {
            limit = this.props.account.allowedViewers;
        }else if( role == "creator" ) {
            limit = this.props.account.allowedCreators;
        }
        else if( role == "admin" ) {
            limit = this.props.account.allowedAdmins;
        }
        return roleCount >= limit
    }

    updateUserField(uid, key, value) {
        if( key == "role" && this.reachedUserLimitNew(value)){
            var temp = this.state.organisationUsers;
            temp[uid]["errorMsg"] = DkScanHowLocalisation.t("web_users_errormsg_youcantcreatemoreusers_params").replace("%1",value);
            this.setState({organisationUsers: temp});
            console.log("updateUserField " + uid + ", " + key +", " + value);
        }else {
            var temp = this.state.organisationUsers;
            temp[uid][key] = value;
            this.setState({organisationUsers: temp});
            console.log("updateUserField " + uid + ", " + key + ", " + value);
        }
    }

    saveUsers(userIdsToSave){
        DkScanHowHelpers.displayModal(true);
        var usersAndGroups= {};

        for(var index = 0 ; index < userIdsToSave.length ; index++){
            var user = this.state.organisationUsers[userIdsToSave[index]];
            if( user && user.uid) {
                var userId = user.uid

                usersAndGroups['users/' + userId + "/email"] = user.email;

                usersAndGroups['users/' + userId + "/name"] = user.name;
                usersAndGroups['users/' + userId + "/role"] = user.role;
                usersAndGroups['users/' + userId + "/modifiedTime"] = firebase.database.ServerValue.TIMESTAMP;

                usersAndGroups['accountUsers/' + this.props.account.accountKey + '/' + userId] = user.role;

                var selectedGroups = DkScanHowHelpers.objectToArray(user.groups);
                selectedGroups.forEach(function (group) {
                    if (group.valueOf()) {
                        usersAndGroups['accountGroups/' + this.props.account.accountKey + '/'+ group.key + '/users/' + userId] = true;
                        usersAndGroups['users/' + userId + "/groups/" + group.key] = true;
                    } else {
                        usersAndGroups['accountGroups/' + this.props.account.accountKey + '/'+ group.key + '/users/' + userId] = null;
                        usersAndGroups['users/' + userId + "/groups/" + group.key] = null
                    }
                }, this);

                //Change password
                if( user.changePassword ){
                    usersAndGroups['users/'+userId+'/changingPassword'] = true;
                    usersAndGroups['userQueues/changePassword/'+userId+'/proposedPassword'] = user.changePassword;
                }
            }else{
                console.log("Missing user id " + userIdsToSave[index]);
            }
        }
        firebase.database().ref().update(usersAndGroups).then(
            this.usersUpdated,
            this.usersUpdateFailed
        );
    }

    usersUpdated(){
        //showSnackbar("UsersUpdated");
        DkScanHowHelpers.displayModal(false);
    }

    usersUpdateFailed(error) {
        this.setState({errorMsg: DkScanHowLocalisation.t("web_general_errormsg_anerroroccurred")});
        console.log("usersUpdateFailed.: "+error);
        DkScanHowHelpers.displayModal(false);
        DkScanHowHelpers.logError(error);
    }

    deleteUser(uid) {
        this.deleteUsers([uid]);
    }

    deleteUsers(userIdsToDelete) {
        var confirmMsg = userIdsToDelete.length == 1 ? "web_users_confirmbox_areyoucertainyouwanttodeletetheuser" : "web_users_confirmbox_areyoucertainyouwanttodeletetheusers";
        if (confirm( DkScanHowLocalisation.t( confirmMsg ) ) ) {


            var multipleRemoveLocatons = {};

            for (var index = 0; index < userIdsToDelete.length; index++) {
                var user = this.state.organisationUsers[userIdsToDelete[index]];
                if (user && user.uid) {
                    var userId = user.uid

                    if (userId == this.props.currentUser.uid) {
                        showSnackbar(DkScanHowLocalisation.t( "web_users_snackbar_youcantdeleteyourownuser" ));
                    } else {

                        multipleRemoveLocatons['users/' + userId] = null;
                        multipleRemoveLocatons['accountUsers/' + this.props.account.accountKey + '/' + userId] = null;

                        var selectedGroups = DkScanHowHelpers.objectToArray(user.groups);
                        selectedGroups.forEach(function (group) {
                            /*if (group.valueOf()) {*/
                            multipleRemoveLocatons['accountGroups/' + this.props.account.accountKey + '/' + group.key + '/users/' + userId] = null;
                            /*}*/
                        }.bind(this));
                    }
                } else {
                    console.log("Missing user id " + userIdsToDelete[index]);
                }
            }
            firebase.database().ref().update(multipleRemoveLocatons).then(
                this.usersDeleted,
                function (error) {
                    console.log("Remove failed: " + error.message)
                    DkScanHowHelpers.displayModal(false);
                    DkScanHowHelpers.logError(error);
                }
            );
        }
    }

    usersDeleted() {
        console.log("Remove succeeded.")
        DkScanHowHelpers.displayModal(false);
    }

    addGroup(newGroupName){
        var existingGroup = DkScanHowHelpers.getFirstItemInArrayByValueOfChildKey(this.state.accountGroups, "groupName", newGroupName);

        if( existingGroup === undefined){
            var groupsListListRef = firebase.database().ref('accountGroups/' + this.props.account.accountKey);
            var newGroupRef = groupsListListRef.push();
            newGroupRef.set({
                'groupName': newGroupName,
                'groupKey': newGroupRef.key
            });
        }else {
            showSnackbar(DkScanHowLocalisation.t("web_users_snackbar_groupalreadyexists"))
        }
    }


    render(){
        /*console.log("UserAdministrationPage Render()");*/
        return (
            <div>
                <div className="pageTitleZone" >
                    <h1 className="pageTitle">{DkScanHowLocalisation.t("web_users_title_useradministration")}</h1>
                    <h3 className="center">{DkScanHowLocalisation.t("web_users_subtitle_useroverview")}</h3>
                    <hr className="scanhowHr topDelimiter"/>
                </div>
                <CreateUserCardNew account={this.props.account} accountGroups={this.state.accountGroups} currentUser={this.props.currentUser} organisationUsers={this.state.organisationUsers} reachedUserLimit={this.reachedUserLimitNew} addGroup={this.addGroup}/>
                <br/>
                <EditUsersCard account={this.props.account} accountGroups={this.state.accountGroups} currentUser={this.props.currentUser} organisationUsers={this.state.organisationUsers}
                               updateUserField={this.updateUserField} saveUsers={this.saveUsers} deleteUser = {this.deleteUser} deleteUsers={this.deleteUsers} addGroup={this.addGroup} />
        </div>
        );
    }
}
UserAdministrationPage.propTypes ={
    account:React.PropTypes.object.isRequired,
    currentUser:React.PropTypes.object.isRequired
};